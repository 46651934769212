body {
  display: block;
  overflow-x: hidden;
  background-color: #f5f5f5;
  margin-bottom: 0 !important;
}
div .p-1 {
  padding: 0.1rem !important;
}
div.p-2 {
  padding: 0.1rem !important;
}

.accordionContainer {
  padding: 1.5px !important;
  /* padding-bottom: 0 !important; */
}
address {
  background-color: #fff;
}

.voter {
  font-size: 0.8rem !important;
}
.accordion-button:not(.collapsed) {
  background: none !important;
  /* color: #ff7722; */
  box-shadow: none !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.accordion-button {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.accordion-item p {
  margin-bottom: 0;
}
.accordion-item img {
  width: 100%;
}
.accordion-body {
  padding: 3px !important ;
}
/*
.accordion-item .active {
  background: url("./assets/lotus.png") center no-repeat;
}

.accordion-button::after,
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: url(assets/downarrow.png) !important;
}
*/

.carousel-control-prev-icon {
  transform: rotate(1deg);
}

.carousel-control-next-icon {
  transform: rotate(358deg);
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-item img {
  width: 10%;
}

.accordion-item button {
  padding: 0.2rem !important;
}

.carousel-indicators [data-bs-target] {
  width: 3px !important;
  height: 3px !important;
  border: 5px solid transparent;
  border-radius: 50% !important;
}
/* 
.accordion-button:not(.collapsed) {
  background: url("./assets/lotus.png") center repeat-x;
  background-size: 90px;
} */

button[aria-expanded="true"] {
  /* content: "H"; */
}

button[aria-expanded="true"] > span {
  display: none;
}
.dlTableData {
  margin-top: 0.1rem !important;
}
.dlTable td {
  padding: 0.2rem !important;
  font-size: 0.8rem !important;
}
.dlTable tr td:first-child {
  width: 12%;
}
table.table.dlTable.bg-white.table-striped.border.mt-1.table {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
li.slide > div {
  width: 100% !important;
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
  height: 100% !important;
}
li:not(.ql-direction-rtl)::before {
  display: none !important;
}
ol li:not(.ql-direction-rtl),
ul li:not(.ql-direction-rtl) {
  padding-left: 0 !important;
}
.carousel .control-dots .dot {
  height: 4px !important;
  width: 4px !important;
  border-radius: 50% !important;
}
@media (max-width: 576px) {
  .baseContainer {
    padding: 0px !important;
  }
  .accordionContainer {
    padding: 0.1rem !important;
  }
  .accordion-item .thumb-img {
    width: 30% !important;
  }
  .slideVideo-arrow.right {
    right: 10px !important;
  }

  .slideVideo-arrow.left {
    left: 10px !important;
  }

  .dlTable tr td:first-child {
    width: 40%;
  }
}

/* VIDEO CAROUSEL STYLES */

/* Additionnal styles */
.video-slideVideor {
  position: relative;
  overflow: hidden;
}
.video-slideVideo {
  width: 100%;
}
.slideVideo {
  position: absolute;
}
.carousel .slide iframe {
  display: inline-block !important;
  width: 100% !important;
  margin: 0 !important;
  border: 0 !important;
}
.slideVideo-arrow {
  position: absolute;
  top: 60%;
  left: 0;
  height: auto;
  width: 30px;
  text-align: center;
  display: table-cell;
  cursor: pointer;
  opacity: 0.7;
}

.slideVideo-arrow:hover {
  opacity: 1;
}

.slideVideo-arrow.right {
  left: auto;
  right: 50px;
}

.slideVideo-arrow.left {
  left: 50px;
}

.slideVideo-arrow img {
  width: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  filter: invert(1) grayscale(100);
}

.slideVideo-arrow.right img {
  transform: rotate(-90deg);
}

.slideVideo-arrow.left img {
  transform: rotate(90deg);
}
